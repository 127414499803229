import { CustomerProfile } from "../global/customerProfile";
import { TFilterTypeInvoice } from "../global/globalInterface";
import { IOrderByNameInvoice } from "../services/invoices/getOrderByNameInterface";

export interface IUserStore {
  customerId: string;
  setCustomerId: (customerId: string) => void;
  emailLocalStorage: string;
  setEmailLocalStorage: (emailLocalStorage: string) => void;
  profile: CustomerProfile;
  setProfile: (profile: CustomerProfile) => void;
  clearReferralUser: () => void;
  referralUser: ReferralUser | null;
  fetchReferralGetUser: (
    typeFilter: string,
    shouldGroupByMonth: boolean
  ) => void;
  referralLinkUser: string | null;
  fetchReferralLinkUser: () => void;
  isModifiedDataAccount: boolean;
  setIsModifiedDataAccount: (isModifiedDataAccount: boolean) => void;
  orderUser: IOrderByNameInvoice | null,
  setOrderUser: (orderUser:IOrderByNameInvoice) => void,
  invoiceType: TFilterTypeInvoice;
  setInvoiceType: (invoiceType: TFilterTypeInvoice) => void;
}

export interface ReferralUser {
  points_balance: number;
  redeem_value: number;
  cupon_history: CuponHistory[] | CuponHistoryTravelClub[];
}

export interface CuponHistoryTravelClub {
  year: number;
  month: number;
  data: CuponHistory[];
}

export interface CuponHistory {
  type: Type;
  date: Date;
  points: number;
  cupon: string;
  cash: number;
}

export enum Type {
  RedeemedCoupon = "redeemed coupon",
}

export interface ReferralUserLink {
  referral_link: string;
}
