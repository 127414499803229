import { ISvg } from "../../../interfaces/components/svg/svgInterface";

const Paypal = ({ className }: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="paypal"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M0.25 4C0.25 1.79086 2.04086 0 4.25 0H32C34.2091 0 36 1.79086 36 4V20C36 22.2091 34.2091 24 32 24H4.25C2.04086 24 0.25 22.2091 0.25 20V4Z"
        fill="white"
      />
      <path
        d="M23.1894 8.31015L23.1448 8.58765C22.534 11.7376 20.4412 12.8327 17.7748 12.8327H16.4193C16.0931 12.8323 15.8152 13.0708 15.7639 13.3952L15.0712 17.8351L14.8701 19.0951C14.855 19.196 14.8841 19.2986 14.9498 19.3763C15.0155 19.4539 15.1114 19.4991 15.2127 19.5001H17.6258C17.912 19.4999 18.1553 19.2899 18.1993 19.0051V18.8851L18.6537 15.9826V15.8251C18.6943 15.5416 18.9352 15.3309 19.2197 15.3301H19.6144C21.9456 15.3301 23.7778 14.3776 24.3066 11.5801C24.5928 10.624 24.4162 9.58806 23.83 8.78265C23.6439 8.59051 23.4275 8.43084 23.1894 8.31015Z"
        fill="#189BD7"
      />
      <path
        d="M22.5503 8.05519L22.2673 7.98019L21.9545 7.92019C21.5601 7.8608 21.1616 7.83321 20.7628 7.83769H17.1282C17.0433 7.83534 16.9591 7.85334 16.8824 7.89019C16.7097 7.97161 16.5893 8.13512 16.5622 8.32519L15.8174 13.2527V13.3952C15.8687 13.0709 16.1467 12.8323 16.4728 12.8327H17.8283C20.4947 12.8327 22.5875 11.7377 23.1983 8.58769L23.243 8.31019C23.0825 8.22671 22.9156 8.15648 22.7439 8.10019L22.5503 8.05519Z"
        fill="#242E65"
      />
      <path
        d="M16.5622 8.32519C16.5893 8.13512 16.7097 7.97161 16.8824 7.89019C16.9591 7.85334 17.0433 7.83534 17.1282 7.83769H20.7628C21.1616 7.83321 21.5601 7.8608 21.9545 7.92019L22.2673 7.98019L22.5503 8.05519L22.6909 8.1C22.8626 8.15629 23.029 8.22667 23.1894 8.31015C23.4315 7.37312 23.2101 6.37589 22.5941 5.6325C21.8493 4.8375 20.6278 4.5 19.034 4.5H14.4014C14.0752 4.4996 13.7973 4.73817 13.746 5.0625L11.8169 17.37C11.7997 17.4861 11.8332 17.604 11.909 17.6932C11.9847 17.7824 12.0951 17.8341 12.2117 17.835L15.0712 17.8351L15.8174 13.2527L16.5622 8.32519Z"
        fill="#253D80"
      />
    </svg>
  );
};

export default Paypal;
