import format from "date-fns/format";
import addDays from "date-fns/addDays";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const wordDate = (__d: any): any => {
  const d = new Date(__d);

  if (isNaN(d.getTime())) {
    return undefined;
  }

  return `${parseInt(d.getUTCDate() as any)}/${
    months[parseInt(d.getUTCMonth() as any)]
  }/${d.getUTCFullYear()}`;
};

export const getMinDate = (): any => addDays(new Date(), 2);

export const getMinDateFormatted = (_d: Date | null = null): string => {
  return format(new Date(_d === null ? getMinDate() : _d), "yyyy-MM-dd");
};

export const getInitialDate = (dString: any): any => {
  if (!dString) return false;
  const d = dString.split("T")[0].split("-");
  return new Date(d[0], d[1] - 1, d[2]);
};

export const isAllowedToModifyActivationDate = (__d: any): any => {
  if (__d === null) return true;
  const _d = new Date(__d);
  const d = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);
  const _minActivationDate = new Date();
  const minActivationDate = new Date(
    _minActivationDate.getFullYear(),
    _minActivationDate.getMonth(),
    _minActivationDate.getDate() + 2,
    0,
    0,
    0
  );

  return d > minActivationDate;
};
