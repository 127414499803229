import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Facebook = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="facebook"
      width="57"
      height="57"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M1.90525 0H15.242C15.7473 0 16.2319 0.210714 16.5892 0.585786C16.9465 0.960859 17.1472 1.46957 17.1472 2V16C17.1472 16.5304 16.9465 17.0391 16.5892 17.4142C16.2319 17.7893 15.7473 18 15.242 18H1.90525C1.39994 18 0.915336 17.7893 0.558033 17.4142C0.200731 17.0391 0 16.5304 0 16V2C0 1.46957 0.200731 0.960859 0.558033 0.585786C0.915336 0.210714 1.39994 0 1.90525 0ZM14.2893 2H11.9078C11.0235 2 10.1754 2.36875 9.55016 3.02513C8.92488 3.6815 8.5736 4.57174 8.5736 5.5V8H6.66836V11H8.5736V18H11.4315V11H14.2893V8H11.4315V6C11.4315 5.73478 11.5318 5.48043 11.7105 5.29289C11.8891 5.10536 12.1314 5 12.3841 5H14.2893V2Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
