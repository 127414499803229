import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Instagram = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="instagram"
      width="57"
      height="57"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M6.39338 0H14.3954C17.4438 0 19.9206 2.6 19.9206 5.8V14.2C19.9206 15.7383 19.3385 17.2135 18.3023 18.3012C17.2661 19.3889 15.8608 20 14.3954 20H6.39338C3.34498 20 0.868164 17.4 0.868164 14.2V5.8C0.868164 4.26174 1.45028 2.78649 2.48646 1.69878C3.52264 0.61107 4.928 0 6.39338 0ZM6.20285 2C5.29331 2 4.42102 2.37928 3.77787 3.05442C3.13472 3.72955 2.77341 4.64522 2.77341 5.6V14.4C2.77341 16.39 4.30713 18 6.20285 18H14.5859C15.4955 18 16.3678 17.6207 17.0109 16.9456C17.6541 16.2705 18.0154 15.3548 18.0154 14.4V5.6C18.0154 3.61 16.4817 2 14.5859 2H6.20285ZM15.3957 3.5C15.7115 3.5 16.0144 3.6317 16.2377 3.86612C16.461 4.10054 16.5864 4.41848 16.5864 4.75C16.5864 5.08152 16.461 5.39946 16.2377 5.63388C16.0144 5.8683 15.7115 6 15.3957 6C15.0798 6 14.777 5.8683 14.5537 5.63388C14.3303 5.39946 14.2049 5.08152 14.2049 4.75C14.2049 4.41848 14.3303 4.10054 14.5537 3.86612C14.777 3.6317 15.0798 3.5 15.3957 3.5ZM10.3944 5C11.6576 5 12.8692 5.52678 13.7624 6.46447C14.6557 7.40215 15.1575 8.67392 15.1575 10C15.1575 11.3261 14.6557 12.5979 13.7624 13.5355C12.8692 14.4732 11.6576 15 10.3944 15C9.13113 15 7.91962 14.4732 7.02636 13.5355C6.1331 12.5979 5.63128 11.3261 5.63128 10C5.63128 8.67392 6.1331 7.40215 7.02636 6.46447C7.91962 5.52678 9.13113 5 10.3944 5ZM10.3944 7C9.63644 7 8.90953 7.31607 8.37357 7.87868C7.83762 8.44129 7.53652 9.20435 7.53652 10C7.53652 10.7956 7.83762 11.5587 8.37357 12.1213C8.90953 12.6839 9.63644 13 10.3944 13C11.1523 13 11.8793 12.6839 12.4152 12.1213C12.9512 11.5587 13.2523 10.7956 13.2523 10C13.2523 9.20435 12.9512 8.44129 12.4152 7.87868C11.8793 7.31607 11.1523 7 10.3944 7Z"
        fill={color}
      />
    </svg>
  );
};

export default Instagram;
