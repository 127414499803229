import { IEsimAdapterGeneric } from "../../../global/customer";
import { IOrderEsim } from "../../../global/orderEsimInterface";

export interface ICardViewWithPaginationProps {
  cards: IEsimAdapterGeneric[];
  loading: boolean;
  showModalTopUp: (data: IOrderEsim) => void;
  initPerPage: number;
  pages_count: number;
  updatePerPage: (newPerPage: number) => void;
  updatePage: (newPage: number) => void;
};
