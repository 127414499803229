import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";
import GrayCardWaveSVG from "../gray-card-wave.svg";
import GreenCardWaveSVG from "../green-card-wave.svg";

const GetSVGByStatus = (color: string) => {
  const colors: { gray: string; green: string } = {
    gray: GrayCardWaveSVG,
    green: GreenCardWaveSVG,
  };
  return colors[color] || colors.green;
};

const CardWave = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <img src={GetSVGByStatus(color)} alt="card wave" className={className} />
    // <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    // 	<path fillRule="evenodd" clipRule="evenodd" d="M0.128832 74.1634C137.22 102.001 215.248 39.9888 360 63.0802L360 6.29444e-05C360 6.29444e-05 0.128781 2.25143e-08 0.028175 4.92378e-09C-0.0724312 -1.26668e-08 0.128832 74.0525 0.128832 74.1634Z" fill={color}/>
    // </svg>
  );
};

export default CardWave;
