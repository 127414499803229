import HeaderWaveSVG from "../top-wave.svg";

const HeaderWave = (): JSX.Element => {
  return (
    <img className="w-full" src={HeaderWaveSVG} alt="header wave" />
    // <svg width="1439" height="122" viewBox="0 0 1439 122" fill="none" xmlns="http://www.w3.org/2000/svg">
    // 	<path fillRule="evenodd" clipRule="evenodd" d="M1441.48 92.164C892.358 205.37 579.813 -46.8125 6.54865e-06 47.0921L1.06656e-05 3.63606e-06C1.06656e-05 3.63606e-06 1441.48 0.000129655 1441.89 0.00012969C1442.29 0.000129725 1441.48 91.7132 1441.48 92.164Z" fill="#F5F7F9"/>
    // </svg>
  );
};

export default HeaderWave;
