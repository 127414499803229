import { ISvg } from "../../../interfaces/components/svg/svgInterface";

const Visa = ({ className }: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="visa"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H31.75C33.9591 0 35.75 1.79086 35.75 4V20C35.75 22.2091 33.9591 24 31.75 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#2A2A6C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6042 13.2528C22.6088 12.1092 21.6722 11.6153 20.9453 11.232C20.4852 10.9893 20.1091 10.791 20.1091 10.5003C20.1091 10.2528 20.3475 9.99029 20.8539 9.92279C21.4567 9.86274 22.0643 9.96904 22.6116 10.2303L22.9245 8.73029C22.3909 8.52881 21.826 8.42468 21.2561 8.42279C19.4984 8.42279 18.277 9.36779 18.277 10.7178C18.277 11.7153 19.1633 12.2703 19.8336 12.6003C20.5039 12.9303 20.7646 13.1628 20.7571 13.4628C20.7571 13.9278 20.206 14.1378 19.6921 14.1453C19.0581 14.1535 18.4326 13.9986 17.8748 13.6953L17.5545 15.1953C18.1857 15.4434 18.8581 15.5681 19.5357 15.5628C21.4051 15.5628 22.634 14.6328 22.6414 13.1928L22.6042 13.2528ZM17.771 8.5426L16.2814 15.4801H14.4939L15.9835 8.5426H17.771ZM25.2636 13.043L26.2021 10.4405L26.7458 13.043H25.2636ZM28.9052 15.503H27.2517L27.0358 14.468H24.7939L24.429 15.503H22.5521L25.2259 9.07552C25.3492 8.76971 25.643 8.56854 25.9707 8.56552H27.4603L28.9052 15.503ZM12.371 15.4579L15.2608 8.52036H13.3243L11.4698 13.2379L10.725 9.22536C10.659 8.81762 10.3085 8.51868 9.89826 8.52036H6.86695L6.82227 8.72286C7.43027 8.84265 8.02083 9.0392 8.57997 9.30786C8.81813 9.42062 8.97951 9.65156 9.00451 9.91536L10.4271 15.4579H12.371Z"
        fill="white"
      />
    </svg>
  );
};

export default Visa;
