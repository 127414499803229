import { SVGProps } from "react";

const TwitterLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#292B2E"
      d="M10.591 2.097a2.313 2.313 0 0 0-2.313 2.27l-.022 1.257a.478.478 0 0 1-.542.465l-1.246-.17C4.83 5.697 3.26 4.943 1.754 3.688c-.477 2.64.455 4.47 2.699 5.88l1.393.876a.48.48 0 0 1 .027.792l-1.27.928c.756.047 1.473.013 2.068-.105 3.763-.751 6.266-3.583 6.266-8.254 0-.38-.808-1.707-2.345-1.707ZM6.684 4.338a3.909 3.909 0 0 1 6.688-2.676c.399-.003.755.067 1.307-.118.33-.11.71.159.63.498-.174.726-.356 1.186-.776 1.762 0 6.096-3.747 9.06-7.548 9.819-2.207.44-5.261-.105-6.806-.97-.295-.165-.173-.547.16-.598.934-.142 2.34-.46 3.265-1.137-1.067-.704-5.22-3.15-2.839-9.621.103-.28.468-.34.67-.12C2.645 2.49 3.87 3.4 5.11 3.91c.924.378 1.15.37 1.574.429l-.001-.001Z"
    />
  </svg>
);
export default TwitterLight;
