import { ISvg } from "../../../interfaces/components/svg/svgInterface";

const Calendary = ({ width = 20, height = 22 }: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="calendary"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        d="M9.648 13.384a.922.922 0 0 1-.697-.293.958.958 0 0 1-.279-.683c0-.278.093-.516.279-.711a.924.924 0 0 1 .697-.293c.279 0 .511.098.697.293a.995.995 0 0 1 .28.711c0 .26-.094.488-.28.683a.922.922 0 0 1-.697.293Zm-4.462 0a.922.922 0 0 1-.697-.293.958.958 0 0 1-.279-.683c0-.278.093-.516.28-.711a.924.924 0 0 1 .696-.293c.28 0 .512.098.697.293a.995.995 0 0 1 .28.711c0 .26-.094.488-.28.683a.922.922 0 0 1-.697.293Zm8.924 0a.922.922 0 0 1-.697-.293.958.958 0 0 1-.279-.683c0-.278.093-.516.279-.711a.924.924 0 0 1 .697-.293c.279 0 .511.098.697.293a.995.995 0 0 1 .28.711c0 .26-.094.488-.28.683a.922.922 0 0 1-.697.293Zm-4.462 4.379a.924.924 0 0 1-.697-.293.961.961 0 0 1-.279-.683c0-.28.093-.516.279-.711a.922.922 0 0 1 .697-.293c.279 0 .511.097.697.293a.991.991 0 0 1 .28.71c0 .26-.094.489-.28.684a.923.923 0 0 1-.697.293Zm-4.462 0a.924.924 0 0 1-.697-.293.961.961 0 0 1-.279-.683c0-.28.093-.516.28-.711a.922.922 0 0 1 .696-.293c.28 0 .512.097.697.293a.991.991 0 0 1 .28.71c0 .26-.094.489-.28.684a.924.924 0 0 1-.697.293Zm8.924 0a.924.924 0 0 1-.697-.293.961.961 0 0 1-.279-.683c0-.28.093-.516.279-.711a.922.922 0 0 1 .697-.293c.279 0 .511.097.697.293a.991.991 0 0 1 .28.71c0 .26-.094.489-.28.684a.924.924 0 0 1-.697.293ZM2.175 21.667a1.936 1.936 0 0 1-1.423-.586 1.936 1.936 0 0 1-.585-1.422V4.712c0-.558.195-1.032.585-1.423.39-.39.865-.585 1.423-.585h1.561V1.198c0-.242.079-.446.237-.614a.791.791 0 0 1 .6-.25c.26 0 .469.083.627.25a.86.86 0 0 1 .237.614v1.506h8.45V1.17c0-.223.084-.418.25-.586a.803.803 0 0 1 .586-.25c.242 0 .442.083.6.25a.825.825 0 0 1 .237.586v1.534h1.562c.558 0 1.032.195 1.422.585.39.39.586.865.586 1.423v14.947c0 .558-.196 1.032-.586 1.422-.39.39-.864.586-1.422.586H2.175Zm0-1.674h14.947a.325.325 0 0 0 .237-.096.325.325 0 0 0 .097-.238V9.173H1.84V19.66c0 .093.033.172.098.238a.322.322 0 0 0 .237.097ZM1.84 7.5h15.616V4.712a.322.322 0 0 0-.097-.237.324.324 0 0 0-.237-.098H2.175a.32.32 0 0 0-.237.098.32.32 0 0 0-.098.237V7.5Z"
        fill="#643560"
      />
    </svg>
  );
};

export default Calendary;
