import { ISvg } from "../../../interfaces/components/svg/svgInterface";

const Mastercard = ({ className }: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="mastercard"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08301 0C1.87387 0 0.0830078 1.79086 0.0830078 4V20C0.0830078 22.2091 1.87387 24 4.08301 24H31.833C34.0421 24 35.833 22.2091 35.833 20V4C35.833 1.79086 34.0421 0 31.833 0H4.08301Z"
        fill="white"
      />
      <path
        d="M31.833 12C31.833 16.4183 28.2513 20 23.833 20C19.4147 20 15.833 16.4183 15.833 12C15.833 7.58172 19.4147 4 23.833 4C28.2513 4 31.833 7.58172 31.833 12Z"
        fill="#F69E1E"
      />
      <path
        d="M20.833 12C20.833 16.4183 17.2513 20 12.833 20C8.41473 20 4.83301 16.4183 4.83301 12C4.83301 7.58172 8.41473 4 12.833 4C17.2513 4 20.833 7.58172 20.833 12Z"
        fill="#EA1D25"
      />
      <path
        d="M18.333 17.8094C19.873 16.3513 20.833 14.2878 20.833 12C20.833 9.71219 19.873 7.64868 18.333 6.19061C16.793 7.64868 15.833 9.71219 15.833 12C15.833 14.2878 16.793 16.3513 18.333 17.8094Z"
        fill="#F26122"
      />
    </svg>
  );
};

export default Mastercard;
