import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Twitter = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="twitter"
      width="57"
      height="57"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M20 2C19.2665 2.35 18.4758 2.58 17.6566 2.69C18.4949 2.16 19.1427 1.32 19.4475 0.31C18.6568 0.81 17.7804 1.16 16.8564 1.36C16.1038 0.5 15.0464 0 13.8461 0C11.6074 0 9.77839 1.92 9.77839 4.29C9.77839 4.63 9.8165 4.96 9.88318 5.27C6.49184 5.09 3.47203 3.38 1.462 0.79C1.10953 1.42 0.909475 2.16 0.909475 2.94C0.909475 4.43 1.62394 5.75 2.72898 6.5C2.05262 6.5 1.42389 6.3 0.87137 6C0.87137 6 0.87137 6 0.87137 6.03C0.87137 8.11 2.28125 9.85 4.14839 10.24C3.80545 10.34 3.44345 10.39 3.07193 10.39C2.81472 10.39 2.55751 10.36 2.30983 10.31C2.82425 12 4.31986 13.26 6.12032 13.29C4.72949 14.45 2.96714 15.13 1.04284 15.13C0.71895 15.13 0.395059 15.11 0.071167 15.07C1.88115 16.29 4.03408 17 6.33942 17C13.8461 17 17.9709 10.46 17.9709 4.79C17.9709 4.6 17.9709 4.42 17.9614 4.23C18.7616 3.63 19.4475 2.87 20 2Z"
        fill={color}
      />
    </svg>
  );
};

export default Twitter;
