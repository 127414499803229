export { default as AmericanExpress } from "./americanExpress/AmericanExpress";
export { default as Attention } from "./attention/Attention";
export { default as CalendaryOutline } from "./calendary/CalendaryOutline";
export { default as CalendaryOutlineSM } from "./calendary/CalendaryOutlineSM";
export { default as CalendaryOutlineXL } from "./calendary/CalendaryOutlineXL";
export { default as calendary } from "./calendary/calendary";
export { default as CardWave } from "./cardWave/CardWave";
export { default as Check } from "./check/Check";
export { default as ChevronRight } from "./chevronRight/ChevronRight";
export { default as Chip } from "./chip/Chip";
export { default as Circle } from "./circle/Circle";
export { default as DecorativeSim } from "./decorativeSim/DecorativeSim";
export * from "./emptyBox/EmptyBox";
export { default as Facebook } from "./facebook/Facebook";
export { default as FacebookLight } from "./facebook/FacebookLight";
export { default as FooterWave } from "./footerWave/FooterWave";
export { default as HeaderWave } from "./headerWave/HeaderWave";
export { default as Heart } from "./heart/Heart";
export { default as ChatSvg } from "./helpCenter/ChatSvg";
export { default as HolaflyCenterLogoSvg } from "./holaflyLogo/HolaflyCenterLogoSvg";
export { default as HolaflyLogo } from "./holaflyLogo/HolaflyLogo";
export { default as Instagram } from "./instagram/Instagram";
export { default as InstagramLight } from "./instagram/InstagramLight";
export { default as Linkedin } from "./linkedin/Linkedin";
export { default as LinkedinLight } from "./linkedin/LinkedinLight";
export { default as LogoMurcia } from "./logos/LogoMurcia";
export { default as LogoutOffset } from "./logout/LogoutOffset";
export { default as Mastercard } from "./mastercard/Mastercard";
export { default as Paypal } from "./paypal/Paypal";
export { default as CallDown } from "./phone/CallDown";
export { default as CallPhoneIn } from "./phone/CallPhoneIn";
export { default as CallPhoneOut } from "./phone/CallPhoneOut";
export { default as ConnectionBadPhone } from "./phone/ConnectionBadPhone";
export * from "./phone/PhoneSwitch";
export { default as Sim } from "./sim/Sim";
export { default as EsimPhone } from "./sim/EsimPhone";
export { default as Twitter } from "./twitter/Twitter";
export { default as TwitterLight } from "./twitter/TwitterLight";
export { default as XTwitter } from "./twitter/XTwitter";
export { default as FaceIcon } from "./user/FaceIcon";
export { default as Visa } from "./visa/Visa";
export { default as ConnectionWifi } from "./wifi/ConnectionWifi";
export { default as YoutubeLight } from "./youtube/YoutubeLight";
export * from "./arrows/ArrowEast";
export * from "./arrows/ArrowWest";
export * from "./emptyBox/EmptyBox";
export * from "./phone/PhoneSwitch";
export { default as footerWaveBottom } from "./footerWave.svg";
export { default as Cashback } from "./cashback/Cashback";
export { default as CashbackSymbol } from "./cashback/CashbackSymbol";
export { default as Monetization } from "./monetization/Monetization";
export { default as UserReferralIcon } from "./user/UserReferralIcon";
export { default as HelpCenterIcon } from "./helpCenter/HelpCenterIcon";
export { default as HolaCoinIcon } from "./coins/HolaCoinIcon";
export { default as HolaCoinOutlineIcon } from "./coins/HolaCoinOutlineIcon";
export * from "./arrows/ArrowChevronDown";
export { default as Stars } from "./stars/stars";
export { default as UserSupport} from "./user/UserSupport"
export { default as WhatsAppIcon }from "./whatsapp/WhatsAppIcon";