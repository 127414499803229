import * as React from "react";
import { SVGProps } from "react";

const CalendaryOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={86} {...props}>
    <path
      d="M7.167 40.665h106.666v50.8c0 6.721 0 10.085-1.308 12.651a12.024 12.024 0 0 1-5.243 5.243c-2.567 1.309-5.93 1.309-12.646 1.309H26.364c-6.716 0-10.08 0-12.646-1.309a12.024 12.024 0 0 1-5.243-5.243c-1.308-2.566-1.308-5.93-1.308-12.652ZM7.167 33.201c0-6.721 0-10.08 1.308-12.651a12.024 12.024 0 0 1 5.243-5.244c2.567-1.308 5.93-1.308 12.646-1.308h68.272c6.716 0 10.08 0 12.646 1.308a12.024 12.024 0 0 1 5.243 5.244c1.308 2.572 1.308 5.93 1.308 12.651v7.464H7.167Zm0 0"
      style={{
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
        strokeWidth: 1,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        stroke: "#292b2e",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      transform="scale(.71074)"
    />
    <path
      d="M27.167 8.167V21.5M92.168 8.167V21.5"
      style={{
        fill: "none",
        strokeWidth: 1,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        stroke: "#292b2e",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      transform="scale(.71074)"
    />
  </svg>
);
export default CalendaryOutline;
