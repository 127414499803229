import { Console } from "console";
import { IOrderEsim } from "../interfaces";
import { useAxios } from "./useAxios";

interface useGetCustomerParams {
  customerId?: string;
  id?: string;
}
interface IUseGetCustomer {
  getCustomerCards: () => Promise<IOrderEsim[] | null>;
  getCustomerProvider: () => Promise<IOrderEsim[] | null>;
  getCustomerCardDetail: () => Promise<IOrderEsim | null>;
}

export const useGetCustomer = ({
  customerId,
  id,
}: useGetCustomerParams): IUseGetCustomer => {
  const { apiCall } = useAxios();

  const params = `${id ? `?id=${id}` : ""}${
    customerId ? `?customer_id=${customerId}` : ""
  }`;

  const getCustomerCards = async (): Promise<IOrderEsim[] | null> => {
    try {
      const { data, status } = await apiCall(
        "GET",
        `${process.env.REACT_APP_API_URL}/api/customerCard${params}`
      );
      const cards: IOrderEsim[] = data;
      if (status === 200) return cards;

      return null;
    } catch (error) {
      return null;
    }
  };

  const getCustomerProvider = async (): Promise<IOrderEsim[] | null> => {
    try {
      const { data, status } = await apiCall(
        "GET",
        `${process.env.REACT_APP_API_URL}/api/customerCard/${params}&includeProvider=true`
      );
      const cards: IOrderEsim[] = data;

      if (status === 200) return cards;

      return null;
    } catch (error) {
      return null;
    }
  };

  const getCustomerCardDetail = async (): Promise<IOrderEsim | null> => {
    try {
      const { data, status } = await apiCall(
        "GET",
        `${process.env.REACT_APP_API_URL}/api/customerCard${params}`
      );
      const card: IOrderEsim = data;

      if (status === 200) return card;

      return null;
    } catch (error) {
      return null;
    }
  };

  return { getCustomerCards, getCustomerProvider, getCustomerCardDetail };
};
