import { t } from "i18next";

// function to redirect to article depending on device
export const openInNewTab = (isIOS: boolean, type?: string) => {
  let guideUrl = "";

  isIOS
    ? (guideUrl = t("global.installationGuide.iOS.textGuide", {
        type: type ? type : "manual",
      }))
    : (guideUrl = t("global.installationGuide.android.textGuide", {
        type: type ? type : "manual",
      }));

  window.open(guideUrl, "_blank", "noopener,noreferrer");
};
