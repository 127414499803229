import { SVGProps } from "react";

export const PhoneSwitch = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      data-testid="phone_switch"
      xmlns="http://www.w3.org/2000/svg"
      width={78}
      height={138}
      fill="none"
      {...props}
    >
      <path
        d="M68.408.6H7.601C3.403.6 0 4.395 0 9.076v118.656c0 4.68 3.403 8.475 7.601 8.475h60.807c4.198 0 7.6-3.795 7.6-8.475V9.076c0-4.681-3.402-8.476-7.6-8.476Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.672C0 4.725 3.61.6 8.197.6h60.807c4.587 0 8.198 4.125 8.198 9.072V128.33c0 4.947-3.61 9.071-8.198 9.071H8.197C3.611 137.4 0 133.276 0 128.329V9.672Zm8.197-7.879c-3.809 0-7.004 3.465-7.004 7.88v118.656c0 4.413 3.195 7.878 7.005 7.878h60.806c3.81 0 7.005-3.465 7.005-7.878V9.672c0-4.414-3.195-7.879-7.005-7.879H8.197Z"
        fill="#292B2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.367 8.107c0-.33.267-.597.597-.597h17.275a.597.597 0 0 1 0 1.193H29.964a.597.597 0 0 1-.597-.596Z"
        fill="#292B2E"
      />
      <path
        d="M12.925 66.947c0-7.614 6.172-13.787 13.786-13.787H51.7c7.614 0 13.786 6.173 13.786 13.787 0 7.614-6.172 13.786-13.786 13.786H26.71c-7.614 0-13.786-6.172-13.786-13.786Z"
        fill="#292B2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.063 66.947c0-8.09 6.558-14.648 14.648-14.648h24.988c8.09 0 14.648 6.558 14.648 14.648s-6.559 14.647-14.648 14.647H26.71c-8.09 0-14.648-6.558-14.648-14.647Zm14.648-12.925c-7.138 0-12.925 5.786-12.925 12.925 0 7.138 5.787 12.924 12.925 12.924h24.988c7.138 0 12.924-5.786 12.924-12.924 0-7.139-5.786-12.925-12.925-12.925H26.712Z"
        fill="#292B2E"
      />
      <path
        d="M65.485 66.947c0 7.614-6.173 13.786-13.787 13.786-7.614 0-13.786-6.172-13.786-13.786S44.084 53.16 51.698 53.16s13.787 6.173 13.787 13.787Z"
        fill="#BFEBFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.698 79.01c6.663 0 12.063-5.401 12.063-12.063 0-6.663-5.4-12.063-12.063-12.063-6.662 0-12.063 5.4-12.063 12.063 0 6.662 5.401 12.063 12.063 12.063Zm0 1.723c7.614 0 13.787-6.172 13.787-13.786S59.312 53.16 51.698 53.16c-7.614 0-13.786 6.173-13.786 13.787 0 7.614 6.172 13.786 13.786 13.786Z"
        fill="#292B2E"
      />
    </svg>
  );
};
