import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const Linkedin = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="linkedin"
      width="57"
      height="57"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <path
        d="M17.7886 18H13.9781V11.25C13.9781 10.19 12.8445 9.31 11.8347 9.31C10.8249 9.31 10.1676 10.19 10.1676 11.25V18H6.35709V6H10.1676V8C10.7963 6.93 12.4158 6.24 13.5018 6.24C15.8833 6.24 17.7886 8.28 17.7886 10.75V18ZM4.45185 18H0.641357V6H4.45185V18ZM2.5466 0C3.05191 0 3.53651 0.210714 3.89381 0.585786C4.25112 0.960859 4.45185 1.46957 4.45185 2C4.45185 2.53043 4.25112 3.03914 3.89381 3.41421C3.53651 3.78929 3.05191 4 2.5466 4C2.0413 4 1.55669 3.78929 1.19939 3.41421C0.842088 3.03914 0.641357 2.53043 0.641357 2C0.641357 1.46957 0.842088 0.960859 1.19939 0.585786C1.55669 0.210714 2.0413 0 2.5466 0Z"
        fill={color}
      />
    </svg>
  );
};

export default Linkedin;
