import { IEsimAdapterGeneric } from "../../../global/customer";

export interface IExpiredSectionOrDefaultProps {
  tabs: {
    active: boolean;
    id: string;
    label: string;
  }[];
  cards: IEsimAdapterGeneric[];
};
