import { ISvg } from "../../../interfaces/components/svg/svgInterface";
import { THEME } from "../../../styles/theme";

const ChevronRight = ({
  className,
  color = THEME.colors.black,
}: ISvg): JSX.Element => {
  return (
    <svg
      data-testid="chevron_right"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(className && { className })}
    >
      <g clipPath="url(#clip0_12_136)">
        <path
          d="M12.4103 8.41641C12.8008 8.02589 12.8008 7.39272 12.4103 7.0022L6.11545 0.707349C5.72601 0.317904 5.0946 0.317904 4.70515 0.707349C4.31571 1.09679 4.31571 1.72821 4.70515 2.11765L10.2968 7.7093L4.70515 13.301C4.31571 13.6904 4.31571 14.3218 4.70515 14.7113C5.0946 15.1007 5.72601 15.1007 6.11545 14.7113L12.4103 8.41641Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_12_136">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.00219727)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRight;
