import { useState } from "react";
export const useConnection = (): {
  status: boolean;
  handleReloadConnection: () => void;
} => {
  const [status, setIsStatus] = useState(true);

  const handleReloadConnection = () => {
    if (navigator.onLine) {
      console.log(navigator.onLine);
      setIsStatus(true);
      return;
    }
    setIsStatus(false);
  };

  return {
    status,
    handleReloadConnection,
  };
};
